import { call, put } from "redux-saga/effects";
import { API_URL, ACTIONS, sagaFunction } from "../_config";
import { CallApi } from "./api/callApi";

export function* getVenueList(action: any): any {
  // const yieldPuts = [
  //     { type: ACTIONS.EVENT.GET_EVENT_GAME_DETAILS},
  //     { type: ACTIONS.EVENT.GET_STATUS_MESSAGE, payload: "status" },
  //     { type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED, payload: "rodeo_event_dtls.login_required" },
  //     { type: ACTIONS.EVENT.SITE_HEADER, payload: "rodeo_event_game_info.enable_header" },
  // ]
  yield call(sagaFunction, action, CallApi.GET, API_URL.VENUE.GET_VENUES, action.payload, true);
}

export function* updateVenuePlaylist(action: any): any {
  yield call(
    sagaFunction,
    action,
    CallApi.POST,
    API_URL.VENUE.UPDATE_VENUE_PLAYLIST + action.payload.venue_id,
    { playlist_id: action.payload.playlist_id },
    true
  );
}
