import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SpotifyAuth, Scopes } from "react-spotify-auth";
import "react-spotify-auth/dist/index.css";
import { SPOTIFY_CLIENT_ID, SPOTIFY_CLIENT_SECRET, URLS } from "src/_config";
import axios from "axios";

const SpotifyAuthComponent = () => {
  const [token, setToken] = useState(null);
  const history = useHistory();
  const code = new URLSearchParams(window.location.search).get("code");

  const clientId = SPOTIFY_CLIENT_ID;
  const clientSecret = SPOTIFY_CLIENT_SECRET;
  const redirectUri = window.location.origin + URLS.SPOTIFYAUTH;

  const getToken = async () => {
    if (!code) return;

    const tokenUrl = "https://accounts.spotify.com/api/token";

    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("code", code);
    params.append("redirect_uri", redirectUri);
    params.append("client_id", clientId);
    params.append("client_secret", clientSecret);

    try {
      const response = await axios.post(tokenUrl, params);
      const { access_token, refresh_token } = response.data;

      // Save tokens to state or local storage
      // console.log("Access Token:", access_token);
      // console.log("Refresh Token:", refresh_token);

      localStorage.setItem("spotifyAccessToken", JSON.stringify({ token: access_token, timestamp: Date.now() }));
      localStorage.setItem("spotifyRefreshToken", refresh_token);

      const currentUrl = localStorage.getItem("currentUrl");
      localStorage.removeItem("currentUrl");
      // window.open(currentUrl + "", "_self");
      if (window.opener) {
        window.opener.location.href = currentUrl; // Redirect to a specific URL
      }

      window.close();
    } catch (error) {
      console.error("Error fetching tokens:", error);
    }
  };

  useEffect(() => {
    // console.log(window.location.search);
    getToken();
  }, [code]);

  // async function getProfile(accessToken: any) {
  //   try {
  //     const response = await fetch("https://api.spotify.com/v1/me", {
  //       headers: {
  //         Authorization: "Bearer " + accessToken,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Error: ${response.status} ${response.statusText}`);
  //     }

  //     const data = await response.json();
  //     console.log(data);
  //   } catch (error) {
  //     // console.error("Failed to fetch playlists:", error);
  //     window.open(
  //       "https://accounts.spotify.com/authorize?response_type=token&client_id=" +
  //         SPOTIFY_CLIENT_ID +
  //         "&scope=playlist-read-private%20user-read-email%20user-read-private&redirect_uri=" +
  //         window.location.origin +
  //         URLS.SPOTIFYAUTH,
  //       "_self"
  //     );
  //   }
  // }

  // useEffect(() => {
  //   console.log({ token });
  //   if (token) {
  //     getProfile(token);
  //     localStorage.setItem("spotifyToken", token + "");
  //   }
  // }, [token]);

  return (
    <>
      {!token ? (
        <>
          <div className="login-body SpotifyAuthComponent-body d-flex align-items-center justify-content-center"></div>
          {/* <div className="login-body SpotifyAuthComponent-body">
          <SpotifyAuth
            redirectUri={window.location.origin + URLS.SPOTIFYAUTH}
            clientID={SPOTIFY_CLIENT_ID}
            scopes={[Scopes.userReadPrivate, Scopes.userReadEmail, Scopes.playlistReadPrivate]} // Scopes for Spotify
            onAccessToken={(token: any) => {
              setToken(token);
              console.log(token);
            }}
          />
        </div> */}
        </>
      ) : (
        <div>
          {/* <h3>Logged in with Spotify</h3>
          <p>Token: {token}</p> */}
        </div>
      )}
    </>
  );
};

export default SpotifyAuthComponent;
