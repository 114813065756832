import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE, sagaFunction } from "../_config";
import { CallApi } from "./api/callApi";

export function* loginUser(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.access_token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          token: resp.data.data.access_token,
        },
      });

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updatePassword(action: any): any {
  // const yieldPuts = [
  //     { type: ACTIONS.EVENT.GET_EVENT_GAME_DETAILS},
  //     { type: ACTIONS.EVENT.GET_STATUS_MESSAGE, payload: "status" },
  //     { type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED, payload: "rodeo_event_dtls.login_required" },
  //     { type: ACTIONS.EVENT.SITE_HEADER, payload: "rodeo_event_game_info.enable_header" },
  // ]
  yield call(sagaFunction, action, CallApi.POST, API_URL.USER.UPDATE_PASSWORD, action.payload, true);
}
