import React from "react";
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { store, persistor } from "./store";
import AppLoader from "./_common/components/elements/full-page-loader/appLoader";
import { ACTIONS, API_URL, APP_STAGE, SELECTED_EVENT_STORAGE, STORAGE } from "./_config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";

function render() {
  const container = document.getElementById("root");
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  root.render(
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Fragment>
            <AppLoader />
            <App />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </React.Fragment>
        </PersistGate>
      </Provider>
    </React.Fragment>
  );
}

function Loader() {
  const container = document.getElementById("root");
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  root.render(
    <React.Fragment>
      <div className="loader-container">
        <div className="loader-base">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const token = localStorage.getItem(STORAGE);
if (token) {
  try {
    Loader();
    axios({
      method: "GET",
      url: API_URL.USER.DETAILS,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((success) => {
        store.dispatch({
          type: ACTIONS.USER.ME,
          payload: success.data.data,
        });
        render();
      })
      .catch((error) => {
        //console.log("Error src/index.tsx file : ", error);
        localStorage.removeItem(STORAGE);
        localStorage.removeItem(SELECTED_EVENT_STORAGE);
        store.dispatch({
          type: ACTIONS.USER.LOGOUT,
        });
        render();
      });
  } catch (e) {
    localStorage.removeItem(STORAGE);
    render();
  }
} else {
  render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (APP_STAGE === "prod") {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
