import { takeLatest, all } from "redux-saga/effects";
import { SAGA_ACTIONS } from "../_config";

import { loginUser, updatePassword } from "./userSaga";

import { getMenuList } from "./commonSaga";

import { getCmsContent } from "./cmsSaga";
import { getVenueList, updateVenuePlaylist } from "./venueSaga";

export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PASSWORD, updatePassword),

    //COMMON Saga
    takeLatest(SAGA_ACTIONS.COMMON.MENU_ITEMS, getMenuList),

    //CMS Saga
    takeLatest(SAGA_ACTIONS.CMS.GET_CMS, getCmsContent),

    //Venue Saga
    takeLatest(SAGA_ACTIONS.VENUE.GET_VENUES, getVenueList),
    takeLatest(SAGA_ACTIONS.VENUE.UPDATE_VENUE_PLAYLIST, updateVenuePlaylist),
  ]);
}
